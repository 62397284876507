import React from "react";

const Save = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.51 5.12109L14.8806 1.49163C14.7132 1.32422 14.5078 1.20145 14.2846 1.13449V1.07422H1.7846C1.38951 1.07422 1.07031 1.39342 1.07031 1.7885V18.2171C1.07031 18.6122 1.38951 18.9314 1.7846 18.9314H18.2132C18.6083 18.9314 18.9275 18.6122 18.9275 18.2171V6.13002C18.9275 5.75056 18.7779 5.38895 18.51 5.12109ZM7.14174 2.68136H12.856V5.00279H7.14174V2.68136ZM17.3203 17.3242H2.67746V2.68136H5.71317V5.71708C5.71317 6.11217 6.03237 6.43136 6.42746 6.43136H13.5703C13.9654 6.43136 14.2846 6.11217 14.2846 5.71708V3.16797L17.3203 6.20368V17.3242ZM9.99888 8.44029C8.22433 8.44029 6.7846 9.88002 6.7846 11.6546C6.7846 13.4291 8.22433 14.8689 9.99888 14.8689C11.7734 14.8689 13.2132 13.4291 13.2132 11.6546C13.2132 9.88002 11.7734 8.44029 9.99888 8.44029ZM9.99888 13.4403C9.01228 13.4403 8.21317 12.6412 8.21317 11.6546C8.21317 10.668 9.01228 9.86886 9.99888 9.86886C10.9855 9.86886 11.7846 10.668 11.7846 11.6546C11.7846 12.6412 10.9855 13.4403 9.99888 13.4403Z"
      fill="currentColor"
    />
  </svg>
);

export default Save;
