import React from "react";

const ReceptionUncolored = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
  >
    <g clip-path="url(#clip0_322_24368)">
      <path
        d="M39.3897 41.699H8.28729C7.77742 41.699 7.4375 41.3591 7.4375 40.8492C7.4375 40.3394 7.77742 39.9995 8.28729 39.9995H39.3897C39.8995 39.9995 40.2394 40.3394 40.2394 40.8492C40.2394 41.3591 39.8995 41.699 39.3897 41.699Z"
        fill="currentColor"
      />
      <path
        d="M43.0438 37.1102H4.54828C4.0384 37.1102 3.69849 36.7702 3.69849 36.2604C3.69849 35.7505 4.0384 35.4106 4.54828 35.4106H43.0438C43.5537 35.4106 43.8936 35.7505 43.8936 36.2604C43.8936 36.7702 43.4687 37.1102 43.0438 37.1102Z"
        fill="currentColor"
      />
      <path
        d="M9.05214 17.9899H4.7182C4.20832 17.9899 3.86841 17.6499 3.86841 17.1401C3.86841 16.6302 4.20832 16.2903 4.7182 16.2903H9.05214C9.56201 16.2903 9.90193 16.6302 9.90193 17.1401C9.90193 17.6499 9.47703 17.9899 9.05214 17.9899Z"
        fill="currentColor"
      />
      <path
        d="M6.50294 22.8337H3.86859C3.35872 22.8337 3.0188 22.4938 3.0188 21.9839C3.0188 21.474 3.35872 21.1341 3.86859 21.1341H6.50294C7.01282 21.1341 7.35273 21.474 7.35273 21.9839C7.35273 22.4938 6.92784 22.8337 6.50294 22.8337Z"
        fill="currentColor"
      />
      <path
        d="M15.8506 22.8337H13.2162C12.7064 22.8337 12.3665 22.4938 12.3665 21.9839C12.3665 21.474 12.7064 21.1341 13.2162 21.1341H15.8506C16.3605 21.1341 16.7004 21.474 16.7004 21.9839C16.7004 22.4938 16.2755 22.8337 15.8506 22.8337Z"
        fill="currentColor"
      />
      <path
        d="M25.1983 22.8337H22.5639C22.054 22.8337 21.7141 22.4938 21.7141 21.9839C21.7141 21.474 22.054 21.1341 22.5639 21.1341H25.1983C25.7081 21.1341 26.048 21.474 26.048 21.9839C26.048 22.4938 25.6232 22.8337 25.1983 22.8337Z"
        fill="currentColor"
      />
      <path
        d="M34.5459 22.8337H31.9116C31.4017 22.8337 31.0618 22.4938 31.0618 21.9839C31.0618 21.474 31.4017 21.1341 31.9116 21.1341H34.5459C35.0558 21.1341 35.3957 21.474 35.3957 21.9839C35.3957 22.4938 35.0558 22.8337 34.5459 22.8337Z"
        fill="currentColor"
      />
      <path
        d="M43.8936 22.8337H41.2592C40.7493 22.8337 40.4094 22.4938 40.4094 21.9839C40.4094 21.474 40.7493 21.1341 41.2592 21.1341H43.8936C44.4034 21.1341 44.7434 21.474 44.7434 21.9839C44.7434 22.4938 44.4034 22.8337 43.8936 22.8337Z"
        fill="currentColor"
      />
      <path
        d="M20.3544 17.9899H16.0204C15.5106 17.9899 15.1707 17.6499 15.1707 17.1401C15.1707 16.6302 15.5106 16.2903 16.0204 16.2903H20.3544C20.8643 16.2903 21.2042 16.6302 21.2042 17.1401C21.2042 17.6499 20.8643 17.9899 20.3544 17.9899Z"
        fill="currentColor"
      />
      <path
        d="M31.7416 17.9899H27.3227C26.8128 17.9899 26.4729 17.6499 26.4729 17.1401C26.4729 16.6302 26.8128 16.2903 27.3227 16.2903H31.6566C32.1665 16.2903 32.5064 16.6302 32.5064 17.1401C32.5064 17.6499 32.1665 17.9899 31.7416 17.9899Z"
        fill="currentColor"
      />
      <path
        d="M43.0438 17.9899H38.7099C38.2 17.9899 37.8601 17.6499 37.8601 17.1401C37.8601 16.6302 38.2 16.2903 38.7099 16.2903H43.0438C43.5537 16.2903 43.8936 16.6302 43.8936 17.1401C43.8936 17.6499 43.5537 17.9899 43.0438 17.9899Z"
        fill="currentColor"
      />
      <path
        d="M8.20253 27.5925H3.86859C3.35872 27.5925 3.0188 27.2525 3.0188 26.7427C3.0188 26.2328 3.35872 25.8929 3.86859 25.8929H8.20253C8.7124 25.8929 9.05232 26.2328 9.05232 26.7427C9.05232 27.2525 8.62742 27.5925 8.20253 27.5925Z"
        fill="currentColor"
      />
      <path
        d="M20.0995 27.5925H15.7656C15.2557 27.5925 14.9158 27.2525 14.9158 26.7427C14.9158 26.2328 15.2557 25.8929 15.7656 25.8929H20.0995C20.6094 25.8929 20.9493 26.2328 20.9493 26.7427C20.9493 27.2525 20.5244 27.5925 20.0995 27.5925Z"
        fill="currentColor"
      />
      <path
        d="M31.9965 27.5925H27.6625C27.1527 27.5925 26.8127 27.2525 26.8127 26.7427C26.8127 26.2328 27.1527 25.8929 27.6625 25.8929H31.9965C32.5063 25.8929 32.8463 26.2328 32.8463 26.7427C32.8463 27.2525 32.5063 27.5925 31.9965 27.5925Z"
        fill="currentColor"
      />
      <path
        d="M43.8937 27.5925H39.5598C39.0499 27.5925 38.71 27.2525 38.71 26.7427C38.71 26.2328 39.0499 25.8929 39.5598 25.8929H43.8937C44.4036 25.8929 44.7435 26.2328 44.7435 26.7427C44.7435 27.2525 44.4036 27.5925 43.8937 27.5925Z"
        fill="currentColor"
      />
      <path
        d="M13.3012 13.231H4.37836C3.86848 13.231 3.52856 12.8911 3.52856 12.3812C3.52856 11.8713 3.86848 11.5314 4.37836 11.5314H13.3012C13.811 11.5314 14.151 11.8713 14.151 12.3812C14.151 12.8911 13.811 13.231 13.3012 13.231Z"
        fill="currentColor"
      />
      <path
        d="M43.3837 13.231H34.4609C33.951 13.231 33.6111 12.8911 33.6111 12.3812C33.6111 11.8713 33.951 11.5314 34.4609 11.5314H43.3837C43.8936 11.5314 44.2335 11.8713 44.2335 12.3812C44.2335 12.8911 43.8936 13.231 43.3837 13.231Z"
        fill="currentColor"
      />
      <path
        d="M28.5974 13.231H19.1647C18.6549 13.231 18.3149 12.8911 18.3149 12.3812C18.3149 11.8713 18.6549 11.5314 19.1647 11.5314H28.5974C29.1073 11.5314 29.4472 11.8713 29.4472 12.3812C29.4472 12.8911 29.1073 13.231 28.5974 13.231Z"
        fill="currentColor"
      />
      <path
        d="M14.1509 32.4363H4.7182C4.20832 32.4363 3.86841 32.0964 3.86841 31.5865C3.86841 31.0766 4.20832 30.7367 4.7182 30.7367H14.1509C14.6608 30.7367 15.0007 31.0766 15.0007 31.5865C15.0007 32.0964 14.6608 32.4363 14.1509 32.4363Z"
        fill="currentColor"
      />
      <path
        d="M43.0439 32.4363H33.6113C33.1014 32.4363 32.7615 32.0964 32.7615 31.5865C32.7615 31.0766 33.1014 30.7367 33.6113 30.7367H43.0439C43.5538 30.7367 43.8937 31.0766 43.8937 31.5865C43.8937 32.0964 43.5538 32.4363 43.0439 32.4363Z"
        fill="currentColor"
      />
      <path
        d="M28.5974 32.4363H19.1647C18.6549 32.4363 18.3149 32.0964 18.3149 31.5865C18.3149 31.0766 18.6549 30.7367 19.1647 30.7367H28.5974C29.1073 30.7367 29.4472 31.0766 29.4472 31.5865C29.4472 32.0964 29.1073 32.4363 28.5974 32.4363Z"
        fill="currentColor"
      />
      <path
        d="M39.5596 8.3872H8.20233C7.69246 8.3872 7.35254 8.04729 7.35254 7.53741C7.35254 7.02754 7.69246 6.68762 8.20233 6.68762H39.5596C40.0695 6.68762 40.4094 7.02754 40.4094 7.53741C40.4094 8.04729 39.9845 8.3872 39.5596 8.3872Z"
        fill="currentColor"
      />
      <path
        d="M23.8386 47.9025C10.7518 47.9025 0.129395 37.1951 0.129395 24.1083C0.129395 11.0216 10.7518 0.39917 23.8386 0.39917C36.9254 0.39917 47.5478 11.0216 47.5478 24.1083C47.5478 37.1951 36.9254 47.9025 23.8386 47.9025ZM23.8386 2.09875C11.6866 2.09875 1.82898 11.9563 1.82898 24.1083C1.82898 36.2604 11.6866 46.1179 23.8386 46.1179C35.9906 46.1179 45.8482 36.2604 45.8482 24.1083C45.8482 11.9563 35.9906 2.09875 23.8386 2.09875Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_322_24368">
        <rect
          width="47.5033"
          height="47.5033"
          fill="white"
          transform="translate(0.129395 0.39917)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ReceptionUncolored;
