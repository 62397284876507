import React from "react";

const Phone = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.28677 1.11395C3.25552 1.11395 1.75552 2.23895 1.38052 2.98895C0.844562 4.06086 0.933019 4.48196 1.28677 5.61395C1.75552 7.11395 3.12985 9.77279 5.03677 11.614C7.75552 14.239 10.2868 15.364 11.4118 15.739C12.5368 16.114 13.7555 15.739 14.5055 14.989C15.2555 14.239 16.0055 13.489 15.1618 12.364C14.5639 11.5668 13.6929 10.6451 12.6305 10.114C11.6645 9.63096 11.0888 9.80677 10.7555 10.489C10.5713 10.8661 10.514 11.5884 10.3805 11.989C10.2121 12.4943 9.53677 12.364 8.78677 11.989C8.06873 11.6299 6.25552 10.114 4.75552 7.86395C3.82616 6.46993 5.31165 6.46089 6.25552 5.98895C7.00552 5.61395 7.23801 4.75144 6.63052 3.73895C5.50552 1.86395 5.13052 1.11395 4.28677 1.11395Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export default Phone;
