import React from "react";

const ReceptionHit = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
  >
    <g clip-path="url(#clip0_322_24329)">
      <path
        d="M37.8235 42.9737H10.0353C9.52546 42.9737 9.18555 42.6338 9.18555 42.1239C9.18555 41.614 9.52546 41.2741 10.0353 41.2741H37.8235C38.3334 41.2741 38.6733 41.614 38.6733 42.1239C38.6733 42.6338 38.3334 42.9737 37.8235 42.9737Z"
        fill="currentColor"
      />
      <path
        d="M24.0568 38.8097C21.8474 38.8097 19.7229 38.4698 17.6834 37.705C17.3435 37.62 17.0035 37.535 16.7486 37.535H5.27643C4.76655 37.535 4.42664 37.1951 4.42664 36.6852C4.42664 36.1754 4.76655 35.8354 5.27643 35.8354H16.8336C17.3435 35.8354 17.8533 35.9204 18.3632 36.0904C22.1023 37.4501 26.1813 37.4501 29.9204 36.0904C30.4303 35.9204 30.9401 35.8354 31.45 35.8354H43.0072C43.517 35.8354 43.857 36.1754 43.857 36.6852C43.857 37.1951 43.517 37.535 43.0072 37.535H31.45C31.1101 37.535 30.8551 37.62 30.5152 37.705C28.3908 38.4698 26.2663 38.8097 24.0568 38.8097Z"
        fill="currentColor"
      />
      <path
        d="M24.0568 35.4106C20.9126 35.4106 17.8533 34.4758 15.304 32.6063C14.7091 32.1814 14.1143 32.0114 13.4344 32.0114H5.27643C4.76655 32.0114 4.42664 31.6715 4.42664 31.1616C4.42664 30.6517 4.76655 30.3118 5.27643 30.3118H13.4344C14.4542 30.3118 15.4739 30.6517 16.3237 31.2466C18.6182 32.8612 21.3375 33.711 24.1418 33.711C26.9461 33.711 29.6654 32.8612 31.9599 31.2466C32.8097 30.6517 33.8294 30.3118 34.8492 30.3118H43.0072C43.517 30.3118 43.857 30.6517 43.857 31.1616C43.857 31.6715 43.517 32.0114 43.0072 32.0114H34.8492C34.1693 32.0114 33.4895 32.1814 32.9796 32.6063C30.2603 34.4758 27.286 35.4106 24.0568 35.4106Z"
        fill="currentColor"
      />
      <path
        d="M15.8139 17.48C15.559 17.48 15.389 17.395 15.2191 17.225L12.9246 14.9306C12.7547 14.7606 12.6697 14.5907 12.6697 14.3357C12.6697 14.0808 12.7547 13.9108 12.9246 13.7409L17.3435 9.32195C17.6834 8.98204 18.1933 8.98204 18.5332 9.32195L20.7427 11.5314C20.9127 11.7014 20.9976 12.0413 20.9976 12.2962C20.9976 12.5512 20.7427 12.8061 20.5727 12.8911C19.7229 13.316 19.0431 13.8258 18.3633 14.4207C17.5985 15.1855 17.0886 16.0353 16.6637 16.9701C16.5787 17.225 16.3238 17.395 16.0688 17.48C15.8989 17.48 15.8139 17.48 15.8139 17.48ZM14.7092 14.3357L15.559 15.1855C15.9839 14.4207 16.4937 13.8258 17.0886 13.231C17.5985 12.7211 18.1083 12.2962 18.7032 11.9563L17.9384 11.1915L14.7092 14.3357Z"
        fill="currentColor"
      />
      <path
        d="M24.0568 30.2269C21.4225 30.2269 18.9581 29.2071 17.0035 27.3376C15.049 25.468 14.1143 23.0036 14.1143 20.2843C14.1143 17.565 15.134 15.1855 17.0035 13.231C18.8731 11.2765 21.3375 10.3417 24.0568 10.3417C26.7761 10.3417 29.1556 11.3615 31.1101 13.231C33.0646 15.1006 33.9994 17.565 33.9994 20.2843C33.9994 23.0036 32.9796 25.383 31.1101 27.3376C29.2405 29.2921 26.7761 30.2269 24.0568 30.2269ZM24.0568 12.0413C21.9323 12.0413 19.8079 12.8061 18.2782 14.4207C15.049 17.6499 15.049 22.8337 18.2782 26.0629C21.5074 29.2921 26.6912 29.2921 29.9204 26.0629C33.1496 22.8337 33.1496 17.6499 29.9204 14.4207C28.3058 12.8911 26.1813 12.0413 24.0568 12.0413Z"
        fill="currentColor"
      />
      <path
        d="M24.0568 26.8277C21.1675 26.8277 18.5332 24.9582 17.7684 22.0689C17.6834 21.644 17.9383 21.1341 18.3632 21.0491C18.7881 20.9641 19.298 21.2191 19.383 21.644C20.0628 24.1933 22.7821 25.723 25.3315 25.0431C25.7564 24.9582 26.2663 25.2131 26.3513 25.638C26.4362 26.0629 26.1813 26.5728 25.7564 26.6577C25.2465 26.7427 24.6517 26.8277 24.0568 26.8277Z"
        fill="currentColor"
      />
      <path
        d="M15.7289 12.9761C15.4739 12.9761 15.304 12.8911 15.134 12.7212L9.27044 6.85761C8.93052 6.51769 8.93052 6.00782 9.27044 5.6679C9.61036 5.32798 10.1202 5.32798 10.4601 5.6679L16.3237 11.5315C16.6636 11.8714 16.6636 12.3813 16.3237 12.7212C16.1537 12.8911 15.8988 12.9761 15.7289 12.9761Z"
        fill="currentColor"
      />
      <path
        d="M37.3136 28.1024C36.9736 28.1024 36.7187 27.9324 36.5487 27.6775C36.3788 27.2526 36.4638 26.7427 36.8887 26.5727L40.7977 24.5332C41.2226 24.2783 41.7325 24.4483 41.9024 24.8731C42.0724 25.298 41.9874 25.8079 41.5625 25.9779L37.6535 28.0174C37.5685 28.0174 37.3985 28.1024 37.3136 28.1024Z"
        fill="currentColor"
      />
      <path
        d="M36.3788 25.0431C36.1238 25.0431 35.8689 24.9582 35.6989 24.7032C35.359 24.3633 35.444 23.7685 35.7839 23.5135L37.8234 21.729C38.1633 21.389 38.7582 21.474 39.0131 21.8139C39.353 22.1539 39.2681 22.7487 38.9281 23.0037L36.8886 24.7882C36.7187 24.9582 36.5487 25.0431 36.3788 25.0431Z"
        fill="currentColor"
      />
      <path
        d="M24.0568 47.9025C10.97 47.9025 0.347656 37.1951 0.347656 24.1083C0.347656 11.0216 10.97 0.39917 24.0568 0.39917C37.1436 0.39917 47.766 11.0216 47.766 24.1083C47.766 37.1951 37.1436 47.9025 24.0568 47.9025ZM24.0568 2.09875C11.9048 2.09875 2.04724 11.9563 2.04724 24.1083C2.04724 36.2604 11.9048 46.1179 24.0568 46.1179C36.2089 46.1179 46.0664 36.2604 46.0664 24.1083C46.0664 11.9563 36.2089 2.09875 24.0568 2.09875Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_322_24329">
        <rect
          width="47.5033"
          height="47.5033"
          fill="white"
          transform="translate(0.347656 0.39917)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ReceptionHit;
