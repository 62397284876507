import React from "react";

const ReceptionMirror = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
  >
    <g clip-path="url(#clip0_322_24354)">
      <path
        d="M4.903 30.9917C4.47811 30.9917 4.13819 30.7367 4.05321 30.3118C3.96823 29.8869 4.22317 29.3771 4.73305 29.2921L19.4344 25.8929L19.8593 23.6835L12.6361 13.9958C12.3812 13.6559 12.4661 13.0611 12.8061 12.8061C13.146 12.5512 13.7408 12.6362 13.9958 12.9761L21.4739 23.0036C21.6439 23.1736 21.6439 23.4285 21.6439 23.6835L20.9641 26.8277C20.8791 27.1676 20.6241 27.4225 20.2842 27.5075L5.15794 30.9917C5.07296 30.9917 4.98798 30.9917 4.903 30.9917Z"
        fill="currentColor"
      />
      <path
        d="M27.0826 27.4225C26.9126 27.4225 26.8277 27.4225 26.6577 27.3376C26.4028 27.2526 26.2328 26.9976 26.2328 26.6577L25.553 21.8989C25.553 21.7289 25.553 21.559 25.638 21.474L29.2921 12.6362C29.462 12.2113 29.9719 11.9563 30.3968 12.2113C30.8217 12.3812 31.0766 12.8911 30.8217 13.316L27.2526 21.9839L27.6775 25.2981L35.0706 21.474C35.4955 21.2191 36.0054 21.389 36.1754 21.8139C36.4303 22.2388 36.2604 22.7487 35.8355 22.9186L27.3375 27.2526C27.3375 27.4225 27.1676 27.4225 27.0826 27.4225Z"
        fill="currentColor"
      />
      <path
        d="M33.371 33.626C33.1161 33.626 32.9461 33.5411 32.7762 33.3711C32.6912 33.2861 32.6062 33.2011 27.5075 26.6578C27.3375 26.4028 27.2525 26.0629 27.4225 25.808L30.1418 19.5195C30.3118 19.0946 30.8216 18.9247 31.2465 19.0946C31.6714 19.2646 31.8414 19.7744 31.6714 20.1993L29.1221 26.0629C31.0766 28.5273 33.7959 32.0114 33.9659 32.2664C34.3058 32.6063 34.2208 33.1162 33.8809 33.4561C33.7959 33.5411 33.626 33.626 33.371 33.626Z"
        fill="currentColor"
      />
      <path
        d="M25.553 36.3454C25.298 36.3454 25.0431 36.2604 24.8731 36.0055C24.7032 35.8355 24.5332 35.5806 23.5985 28.1874L19.0946 33.711C18.8396 34.0509 18.2448 34.1359 17.9049 33.881C17.5649 33.626 17.48 33.0312 17.7349 32.6913L23.4285 25.638C23.6834 25.3831 24.0234 25.2981 24.2783 25.3831C24.6182 25.468 24.8731 25.723 24.8731 26.0629C25.383 30.0569 26.0629 34.5608 26.2328 35.1557C26.4028 35.4956 26.3178 35.9205 25.9779 36.1754C25.8929 36.2604 25.7229 36.3454 25.553 36.3454Z"
        fill="currentColor"
      />
      <path
        d="M12.5512 29.2921C12.4662 29.2921 12.3813 29.2921 12.2113 29.2071C11.7864 29.0372 11.5315 28.5273 11.7014 28.1024C11.9564 27.5075 15.6105 20.7092 16.7152 18.6697C16.8852 18.3298 17.3101 18.1598 17.7349 18.2448L26.6578 21.0491C27.0827 21.2191 27.3376 21.644 27.2526 22.1538C27.0827 22.5787 26.6578 22.8337 26.1479 22.7487L17.9049 20.1993C16.1203 23.5135 13.571 28.3573 13.316 28.8672C13.1461 29.0372 12.8911 29.2921 12.5512 29.2921Z"
        fill="currentColor"
      />
      <path
        d="M12.8912 32.1814C12.4663 32.1814 12.1264 31.9264 12.0414 31.5016C11.9565 31.0767 12.2964 30.5668 12.7213 30.4818L19.9445 29.2921L19.3496 26.9127C19.2647 26.4878 19.5196 25.9779 19.9445 25.8929C20.3694 25.808 20.8793 26.0629 20.9642 26.4878L21.814 29.802C21.899 30.0569 21.814 30.3118 21.729 30.4818C21.5591 30.6518 21.3891 30.8217 21.1342 30.8217L12.9762 32.1814H12.8912Z"
        fill="currentColor"
      />
      <path
        d="M28.6123 32.6063C28.3573 32.6063 28.1024 32.5213 27.9324 32.2663L23.4285 26.5727C23.1736 26.3178 23.1736 25.8929 23.3435 25.638C23.5135 25.2981 23.8534 25.2131 24.1933 25.2131L27.1676 25.7229C27.5925 25.8079 27.9324 26.2328 27.8474 26.6577C27.7625 27.0826 27.3376 27.4225 26.9127 27.3376L26.1479 27.2526L29.2921 31.2466C29.547 31.5865 29.547 32.1814 29.1221 32.4363C28.9522 32.5213 28.7822 32.6063 28.6123 32.6063Z"
        fill="currentColor"
      />
      <path
        d="M33.626 39.6596H6.60265C6.34771 39.6596 6.09277 39.5746 5.92281 39.3196C2.35369 35.0707 0.39917 29.717 0.39917 24.1084C0.39917 21.729 0.739086 19.4345 1.41892 17.2251C1.5039 17.0551 1.58888 16.9701 1.67386 16.8002L7.45244 11.7014C9.74687 9.66192 12.7211 8.55719 15.7804 8.55719H33.711C38.2149 8.55719 41.954 12.2113 41.954 16.7152V31.3316C41.869 36.0055 38.2149 39.6596 33.626 39.6596ZM6.94256 37.96H33.626C37.1951 37.96 40.1694 35.0707 40.1694 31.5016V16.8002C40.1694 13.231 37.2801 10.3418 33.626 10.3418H15.7804C13.146 10.3418 10.5967 11.2765 8.64215 13.0611L2.94854 17.9899C2.35369 20.0294 2.09875 22.0689 2.09875 24.1934C2.09875 29.1221 3.79834 34.0509 6.94256 37.96Z"
        fill="currentColor"
      />
      <path
        d="M32.3513 36.3453H6.17775C5.32796 36.3453 4.56315 36.2604 3.79834 36.0054C3.5434 35.9204 3.37344 35.8355 3.28846 35.5805C1.5039 32.2663 0.484149 28.5273 0.39917 24.7032C0.39917 24.6182 0.39917 24.5332 0.39917 24.4483C0.994024 22.6637 2.18373 21.0491 3.88331 19.6044L9.40696 14.8456C11.6164 13.0611 13.8259 12.1263 16.2053 12.1263H32.3513C36.2604 12.1263 38.4698 14.1658 38.4698 17.8199V30.5668C38.5548 34.2209 36.2604 36.3453 32.3513 36.3453ZM4.64813 34.4758C5.158 34.5608 5.66788 34.6458 6.17775 34.6458H32.3513C35.3256 34.6458 36.7702 33.2861 36.7702 30.6517V17.9049C36.7702 15.2705 35.2406 13.9108 32.3513 13.9108H16.2053C14.2508 13.9108 12.3812 14.6757 10.5117 16.2053L4.98804 20.9641C3.5434 22.1538 2.52365 23.5135 2.09875 24.8732C2.18373 28.1873 3.1185 31.5015 4.64813 34.4758Z"
        fill="currentColor"
      />
      <path
        d="M24.1083 47.9025C11.0216 47.9025 0.39917 37.1951 0.39917 24.1083C0.39917 11.0216 11.0216 0.39917 24.1083 0.39917C37.1951 0.39917 47.8175 11.0216 47.8175 24.1083C47.8175 37.1951 37.1951 47.9025 24.1083 47.9025ZM24.1083 2.09875C11.9563 2.09875 2.09875 11.9563 2.09875 24.1083C2.09875 36.2604 11.9563 46.1179 24.1083 46.1179C36.2604 46.1179 46.1179 36.2604 46.1179 24.1083C46.1179 11.9563 36.2604 2.09875 24.1083 2.09875Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_322_24354">
        <rect
          width="47.5033"
          height="47.5033"
          fill="white"
          transform="translate(0.39917 0.39917)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ReceptionMirror;
