import React from "react";

const ReceptionScratch = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
  >
    <g clip-path="url(#clip0_322_24343)">
      <path
        d="M3.1184 33.2997C2.86346 33.2997 2.60853 33.1298 2.43857 32.9598C2.18363 32.5349 2.26861 32.025 2.69351 31.7701L38.8096 6.70126C39.2345 6.44633 39.7444 6.53131 39.9993 6.9562C40.2543 7.3811 40.1693 7.89097 39.7444 8.14591L3.62828 33.1298C3.45832 33.2148 3.28836 33.2997 3.1184 33.2997Z"
        fill="currentColor"
      />
      <path
        d="M12.0412 40.6079C11.7863 40.6079 11.6163 40.5229 11.4463 40.353C11.1064 40.0131 11.1064 39.5032 11.4463 39.1633L33.7959 18.3434C34.1358 18.0035 34.6456 18.0035 34.9856 18.3434C35.3255 18.6833 35.3255 19.1932 34.9856 19.5331L12.5511 40.353C12.3811 40.5229 12.2112 40.6079 12.0412 40.6079Z"
        fill="currentColor"
      />
      <path
        d="M38.2148 16.2189C37.9598 16.2189 37.7899 16.1339 37.6199 15.964C37.28 15.6241 37.28 15.1142 37.6199 14.7743L40.3393 12.3099C40.6792 11.97 41.1891 11.97 41.529 12.3099C41.8689 12.6498 41.8689 13.1597 41.529 13.4996L38.8096 15.964C38.6397 16.1339 38.4697 16.2189 38.2148 16.2189Z"
        fill="currentColor"
      />
      <path
        d="M22.0688 41.6277C21.8138 41.6277 21.6439 41.5427 21.4739 41.3728C21.134 41.0329 21.134 40.523 21.4739 40.1831L37.9599 23.5272C38.2998 23.1872 38.8097 23.1872 39.1496 23.5272C39.4895 23.8671 39.4895 24.3769 39.1496 24.7169L22.7486 41.3728C22.5787 41.5427 22.3237 41.6277 22.0688 41.6277Z"
        fill="currentColor"
      />
      <path
        d="M41.784 35.5942C41.5291 35.5942 41.2742 35.5092 41.1042 35.2543L17.7349 6.95621C17.395 6.53131 17.48 6.02144 17.8199 5.7665C18.1598 5.42658 18.7547 5.51156 19.0096 5.85148L42.4639 34.2345C42.8038 34.5744 42.7188 35.1693 42.3789 35.4242C42.2089 35.5092 42.039 35.5942 41.784 35.5942Z"
        fill="currentColor"
      />
      <path
        d="M37.8748 43.4122C37.6199 43.4122 37.365 43.3272 37.195 43.1573L11.6163 15.3691C11.2764 15.0292 11.2764 14.5193 11.7013 14.1794C12.0412 13.8395 12.5511 13.8395 12.891 14.2644L38.4697 41.9676C38.8096 42.3075 38.8096 42.8174 38.3847 43.1573C38.2148 43.3272 38.0448 43.4122 37.8748 43.4122Z"
        fill="currentColor"
      />
      <path
        d="M24.1083 48.0861C11.0216 48.0861 0.39917 37.3787 0.39917 24.2919C0.39917 11.2052 11.0216 0.582764 24.1083 0.582764C37.1951 0.582764 47.8175 11.2052 47.8175 24.2919C47.8175 37.3787 37.1951 48.0861 24.1083 48.0861ZM24.1083 2.28235C11.9563 2.28235 2.09875 12.1399 2.09875 24.2919C2.09875 36.444 11.9563 46.3015 24.1083 46.3015C36.2604 46.3015 46.1179 36.444 46.1179 24.2919C46.1179 12.1399 36.2604 2.28235 24.1083 2.28235Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_322_24343">
        <rect
          width="47.5033"
          height="47.5033"
          fill="white"
          transform="translate(0.39917 0.582764)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ReceptionScratch;
