import React from "react";

const Pencil = () => (
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.97168 18.5859L4.91488 20.5859"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6592 3.58594L19.4888 9.58594L13.6592 3.58594Z"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.7266 4.88868C22.1036 5.27675 22.1036 5.89432 21.7266 6.28238L8.8012 19.5855L2 21.5855L3.9432 14.5855L16.8284 1.32371C17.2211 0.919511 17.8701 0.919512 18.2628 1.32371L21.7266 4.88868Z"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      d="M6.8584 16.5859L14.6312 8.58594"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
);

export default Pencil;
